













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DegreeOfDisabilitySelect extends Vue {
  @Prop({ type: Number }) value?: number;
  @Prop({ type: Boolean, default: false }) required!: boolean;

  get list() {
    return [
      { value: null, text: 'Keine Behinderung' },
      ...Array.from(new Array(9), (val, index) => ({ value: (index + 2) * 10, text: (index + 2) * 10 }))
    ];
  }

  created() {
    this.$emit('input', this.value);
  }
}
