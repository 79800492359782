

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_MEDICAL_INFORMATION } from '../../../store/actions';
import { AddMedicalInformation, ConservatorshipDetails } from '../../../types';
import { ApiResponse } from '@/components/types';
import MarkerCheckboxGroup from '../../MarkerCheckboxGroup.vue';
import DegreeOfDisabilitySelect from '../../DegreeOfDisabilitySelect.vue';
import LevelOfCareSelect from '../../LevelOfCareSelect.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    MarkerCheckboxGroup,
    DegreeOfDisabilitySelect,
    LevelOfCareSelect
  }
})
export default class AddMedicalInformationDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(ADD_MEDICAL_INFORMATION) addMedicalInformation!: (params: AddMedicalInformation) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: AddMedicalInformation = {
    conservatee_id: this.conservatorship.id,
    level_of_care: this.conservatorship.levelOfCare,
    degree_of_disability: this.conservatorship.degreeOfDisability,
    markers: this.conservatorship.markers,
    doctor: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatee_id: this.conservatorship.id,
        level_of_care: this.conservatorship.levelOfCare,
        degree_of_disability: this.conservatorship.degreeOfDisability,
        markers: this.conservatorship.markers,
        doctor: ''
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.addMedicalInformation(this.value);
  }
}
