

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Marker } from '../types';

@Component({})
export default class MarkerCheckboxGroup extends Vue {
  @Prop({ type: Array, default: () => [] }) value!: Marker[];

  list: Array<{ value: Marker, label: string }> = [
    { value: 'G', label: 'Beeinträchtigung der Bewegungsfähigkeit (G)' },
    { value: 'aG', label: 'Außergewöhnliche Gehbehinderung (aG)' },
    { value: 'H', label: 'Hilflosigkeit (H)' },
    { value: 'Bl', label: 'Blindheit (Bl)' },
    { value: 'Gl', label: 'Gehörlosigkeit (Gl)' },
    { value: 'TBl', label: 'Taubblindheit (TBl)' },
    { value: 'B', label: 'Begleitperson (B)' },
    { value: '1Kl', label: '1. Klasse (1Kl)' },
    { value: 'RF', label: 'Rundfunk/Fernsehen (RF)' },
    { value: 'Kriegsbeschädigt', label: 'Kriegsbeschädigt' },
    { value: 'VB', label: 'Versorgungsberechtigt (VB)' },
    { value: 'EB', label: 'Entschädigungsberechtigt (EB)' }
  ];

  get items() {
    return this.list.sort((first, second) => first.label.localeCompare(second.label));
  }

  get selected(): Marker[] {
    return this.value;
  }

  set selected(markers: Marker[]) {
    this.$emit('input', markers);
  }
}
